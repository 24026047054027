import React from 'react'

import Helmet from 'react-helmet'
import { Grommet } from 'grommet'
import styled, { createGlobalStyle } from 'styled-components'

import Header from 'layout/Header'
import Footer from 'layout/Footer'
import { theme } from 'theme'

import PatternSVG from 'images/wireform-pattern.svg'

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby {
    height: 100%;
    padding: 0;
    margin: 0;
  }
  input, textarea {
    font-family:inherit;
  }
  body {
    background: url(${PatternSVG}) 0 -100px repeat-x;
    background-size: 1000px;
  }
`

const FlexGrommet = styled(Grommet)`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex-grow: 2;
`

const Base = ({ children }) => (
  <>
    <Helmet
      title="Wireform - Jeff Merrick"
      meta={[
        {
          name: 'description',
          content: 'Multidisciplinary product designer and frontend developer based in Chicago.',
        },
        {
          property: 'og:image',
          content: 'https://wireform.io/preview-image.png',
        },
      ]}
      htmlAttributes={{ lang: 'en' }}
    />
    <GlobalStyle />
    <FlexGrommet theme={theme} full>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </FlexGrommet>
  </>
)

export default Base
