import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Box } from 'grommet'

import Container from 'components/Container'
import { MonoHeading } from 'components/Type'

import animationData from 'animations/wireform-logo.json'
import lottie from 'lottie-web'

const Animation = styled.div`
  width: 100%;
  max-width: 200px;
  min-height: 114px;
`

const Header = () => {
  const lottieRef = useCallback(node => {
    if (node !== null) {
      lottie.loadAnimation({
        container: node,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
        name: 'logo',
      })
    }
  }, [])

  const playReverse = () => {
    lottie.setDirection(-1, 'logo')
    lottie.setSpeed(5, 'logo')
    lottie.play('logo')
  }

  const play = () => {
    lottie.setDirection(1, 'logo')
    lottie.setSpeed(1, 'logo')
    lottie.play('logo')
  }

  return (
    <Container>
      <Box
        direction="column"
        justify="between"
        align="center"
        pad={{ horizontal: 'medium', top: 'large' }}
      >
        <Box>
          <Animation
            ref={lottieRef}
            onMouseEnter={playReverse}
            onTouchStart={playReverse}
            onMouseLeave={play}
            onTouchEnd={play}
          />
        </Box>
        <MonoHeading level={3} size="18px" margin={{ top: 'large' }}>
          Wireform
        </MonoHeading>
      </Box>
    </Container>
  )
}

export default Header
