import React from 'react'
import styled from 'styled-components'

import { Box, Button } from 'grommet'
import { Github, Linkedin, Instagram, Mail } from 'grommet-icons'

import Container from 'components/Container'
import { MonoHeading } from 'components/Type'

const FooterButton = styled(({ ...other }) => <Button target="_blank" color="white" {...other} />)`
  margin: 0 10px;
  &:hover {
    box-shadow: none;
    background: rgba(0, 0, 0, 0.25);
  }
`

const Footer = () => (
  <Box background="dark-1" pad={{ bottom: 'large' }} margin={{ top: 'auto' }} flex="grow">
    <Container>
      <MonoHeading level={3} size="16px" margin="medium" textAlign="center">
        Find Me
      </MonoHeading>
      <Box pad={{ top: 'small' }} direction="row-responsive" full>
        <FooterButton
          icon={<Github />}
          label="Github"
          href="https://github.com/jeffmerrick"
          rel="noreferrer"
        />
        <FooterButton
          icon={<Linkedin />}
          label="Linkedin"
          href="https://www.linkedin.com/in/jeffreymerrick"
          rel="noreferrer"
        />
        <FooterButton
          icon={<Instagram />}
          label="Instagram"
          href="https://www.instagram.com/jeffmerrick"
          rel="noreferrer"
        />
        <FooterButton
          icon={<Mail />}
          label="Email"
          href="mailto:jeff@wireform.io"
          rel="noreferrer"
        />
      </Box>
    </Container>
  </Box>
)

export default Footer
